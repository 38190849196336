import { useHistory, useParams } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';
// @ts-ignore
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView';
import { getNotiDetail, getNotiDetailByContentId, getNotiDetailByKey } from '../../apis/notification';
import { NotiMessageDto } from '../../types/notification';
import { nToast } from '../../plugins/reactToast';
import { Bridge } from '../../plugins/bridge';

const NotificationDetailPage = () => {
  const { id } = useParams<{ id: string }>();
  const numId = parseInt(id);
  const { key } = useParams<{ key: string }>();
  const { contentId } = useParams<{ contentId: string }>();
  const [content, setContent] = useState<NotiMessageDto>();
  const contentDiv = useRef<HTMLDivElement>(null);
  const history = useHistory();

  const getDetail = () => {
    if (!!key) return getNotiDetailByKey(key);
    if (!!contentId) return getNotiDetailByContentId(Number(contentId));
    return getNotiDetail(numId);
  };

  useEffect(() => {
    getDetail()
      .then(r => {
        setContent(r.data.payload);
        Bridge.sendAmplitude('notification_content_viewed', {
          content_id: r.data.payload.id,
          content_name: r.data.payload.title,
        });
      })
      .catch(e => {
        nToast('조회 권한이 없습니다.');
        history.replace('/notification');
      });
  }, []);

  useEffect(() => {
    document.getElementsByTagName('main')[0].style.backgroundColor = content?.bgColorCode!;
    document.getElementsByTagName('main')[0].style.color = content?.textColorCode!;
    const spanList = document.getElementsByTagName('span');
    //@ts-ignore
    for (let item of spanList) {
      if (item.id !== 'counter-badge') {
        item.style.color = content?.textColorCode!;
      }
    }
    return () => {
      document.getElementsByTagName('main')[0].style.backgroundColor = '';
      document.getElementsByTagName('main')[0].style.color = '';
      //@ts-ignore
      for (let item of spanList) {
        item.style.color = '';
      }
    };
  }, [content]);

  if (!content) return null;
  return (
    <div className="pt-11 -mx-4 overflow-hidden">
      <style dangerouslySetInnerHTML={{
        __html: `
              .fr-view img.fr-dib{margin:0 auto;}
              h4{font-weight:400;font-size:12px;}
              .fr-view img{object-fit:cover;}
              .contents_list2{
              display:flex;
              flex-wrap: wrap;
              max-width:1300px;
              margin:0 auto;
              font-size:12px;
              column-gap: 10px;
              justify-content: center;
              font-weight:800;
              }
              .contents_list2 h2, .contents_list2 h3, .contents_list2 h4{
              font-size:12px;
              line-height:1.2;
              font-weight:700;
              }
              .contents_list2 h3{
              text-decoration:line-through;
              color:#9298a5;
              }
              .contents_list2 h3, .contents_list2 h4{
              display:inline-block;
              }
              .contents_list2 h4{color:#ec393c; margin-right:5px;}
              .contents_list2>div{
              width:200px;
              margin-bottom:80px;
              }
              .contents_list2>div>p{
              position:relative;
              padding:0 !important;
              }
              .contents_list2 img{
              width:100%;
              height:100%;
              }
              .contents_list2 span{
              position:absolute;
              top:0;
              left:0;
              width:100%;
              height:100%;
              background:#fff;
              opacity:0;
              z-index:1;
              transition:0.2s all;
              text-indent:-9999px;
              }
              .contents_list2 span:hover{
              position:absolute;
              top:0;
              left:0;
              width:100%;
              height:100%;
              background:white;
              opacity:0.6;
              transition:0.2s all;
              cursor:pointer;
              }
              @media (max-width: 640px) {
                .contents_list2{
                display:grid;
                padding:50px 10px; 
                grid-template-columns: repeat(2, minmax(0, 1fr));
                
                }
                .contents_list2>div{
                width:100%;
                padding-bottom:10px;
                }
                .contents_list2 h4{color:#ec393c; margin-right:5px;}
                .contents_list2>div{
                width:200px;
                margin-bottom:20px;
              }
              }
              .contents_list3{
              display: flex;
              flex-wrap: wrap;
              max-width: 1600px;
              padding-top:20px;
              margin: 72px auto 20px auto;
              font-size: 12px;
              gap: 10px;
              justify-content: center;
              overflow: hidden;
              }
              .contents_list3 div{
              overflow:hidden;
              width:250px;
              position:relative;
              font-size:0;
              }
              .contents_list3 a{
              background:rgba(255,255,255,0.5);
              opacity:0;
              position:absolute;
              width:100%;
              height:100%;
              flex-direction: column;
              display:flex;
              justify-content: center;
              z-index:10;
              top:0;
              text-align:center;
              transition-duration: 300ms;
              }
              .contents_list3 div a:hover{
              opacity:1;
              transition-duration: 300ms;
              }
              .contents_list3 div a span{
              font-size:12px;
              color:black;
              font-weight:500;
              }

              .contents_list_black{
              display: flex;
              flex-wrap: wrap;
              max-width: 1600px;
              margin: 72px auto 20px auto;
              font-size: 12px;
              column-gap: 10px;
              justify-content: center;
              overflow: hidden;
              }
              .contents_list_black div{
              overflow:hidden;
              width:250px;
              position:relative;
              }
              .contents_list_black a{
              position:absolute;
              top:0;
              left:0;
              width:100%;
              font-weight:600;
              font-size:13px;
              text-align:center;
              opacity:0;
              color:white;
              height:100%;
              background:rgba(0,0,0,0);
              z-index:1;
              transition:0.2s all;
              }
              .contents_list_black div a:hover{
              position:absolute;
              top:0;
              left:0;
              width:100%;
              height:100%;
              background:rgba(0,0,0,0.5);
              transition:0.2s all;
              opacity:1;
              cursor:pointer;
              }
              .contents_list_black div a span{
              position:absolute;
              width:100%;
              left:0;
              right:0;
              top:45%;
              }
              
            
              .contents_list_gray{
              display:grid;
              grid-template-columns: repeat(4, minmax(0, 1fr));
              max-width:1400px;
              margin:0 auto;
              }
              .contents_list_gray div{
              overflow:hidden;
              margin:0 15px;
              }
              .contents_list_gray img{
              
              }
              .contents_list_gray a{
              position:absolute;
              top:0;
              left:0;
              width:100%;
              font-weight:600;
              font-size:13px;
              text-align:center;
              opacity:0;
              color:white;
              height:100%;
              background:rgba(255,255,255,0);
              z-index:1;
              transition:0.2s all;
              }
              .contents_list_gray div{
              position:relative;
              }
              .contents_list_gray div a:hover{
              position:absolute;
              top:0;
              left:0;
              width:100%;
              height:100%;
              background:rgba(132, 132, 132, 0.7);
              transition:0.2s all;
              opacity:1;
              cursor:pointer;
              }
              .contents_list_gray div a span{
              position:absolute;
              width:100%;
              left:0;
              right:0;
              top:50%;
              }
              
              
              .contents_list5{
              display:grid;
              grid-template-columns: repeat(3, minmax(0, 1fr));
              max-width:1400px;
              margin:0 auto;
              }
              .contents_list5 div{
              overflow:hidden;
              margin:0 15px;
              }
              .contents_list5 img{
              
              }
              .contents_list5 a{
              position:absolute;
              top:0;
              left:0;
              width:100%;
              font-weight:600;
              font-size:13px;
              text-align:center;
              opacity:0;
              color:black;
              height:100%;
              background:rgba(255,255,255,0);
              z-index:1;
              transition:0.2s all;
              }
              .contents_list5 div{
              position:relative;
              }
              .contents_list5 div a:hover{
              position:absolute;
              top:0;
              left:0;
              width:100%;
              height:100%;
              background:rgba(255,255,255,0.7);
              transition:0.2s all;
              opacity:1;
              cursor:pointer;
              }
              .contents_list5 div a span{
              position:absolute;
              width:100%;
              left:0;
              right:0;
              top:50%;
              }
              
              @media (max-width: 1300px) {
              .contents_list3{
              display: flex;
              flex-wrap: wrap;
              max-width: 1600px;
              padding-top:20px;
              margin: 72px auto 20px auto;
              font-size: 12px;
              gap: 10px;
              justify-content: center;
              overflow: hidden;
              }
              .contents_list3 div a span{
              font-size:10px;
              color:black;
              font-weight:500;
              }
              .contents_list_black{
              display: flex;
              flex-wrap: wrap;
              max-width: 1600px;
              margin: 72px auto 20px auto;
              font-size: 12px;
              column-gap: 10px;
              justify-content: center;
              overflow: hidden;
              }
              .contents_list_gray{
              display:grid;
              grid-template-columns: repeat(3, minmax(0, 1fr));
              max-width:1400px;
              margin:0 auto;
              }
              
              .contents_list5{
              display:grid;
              grid-template-columns: repeat(3, minmax(0, 1fr));
              max-width:1400px;
              margin:0 auto;
              }
              }
              @media (max-width: 640px) {
              .contents_list3{
              display: flex;
              flex-wrap: wrap;
              max-width: 1600px;
              padding-top:20px;
              margin: 72px auto 20px auto;
              font-size: 12px;
              gap: 10px;
              justify-content: center;
              overflow: hidden;
              }
              .contents_list3 div{
              overflow:hidden;
              width:45%;
              position:relative;
              }
              .contents_list_black{
              display:grid;
              grid-template-columns: repeat(2, minmax(0, 1fr));
              max-width:1400px;
              margin:0 10px;
              }
              .contents_list_black div{
              overflow:hidden;
              width:100%;
              position:relative;
              }
              .contents_list_gray{
              display:grid;
              grid-template-columns: repeat(2, minmax(0, 1fr));
              max-width:1400px;
              margin:0 auto;
              }
              .contents_list_gray div{
              overflow:hidden;
              margin:0 2px;
              }
              
              .contents_list5{
              display:grid;
              grid-template-columns: repeat(2, minmax(0, 1fr));
              max-width:1400px;
              margin:0 auto;
              }
              .contents_list5 div{
              overflow:hidden;
              margin:0 2px;
              }
              }
              -webkit-tap-highlight-color : transparent;
            `,
      }} />
      <div className="px-4">
        <h1 className="font-bold text-17">{content.title}</h1>

        <span className="text-12 pt-8">{dayjs(content.displayStartDate).format('YYYY-MM-DD')}</span>
      </div>

      <div id={'journal'}
           ref={contentDiv}
           className={'px-4'}
           style={{
             backgroundColor: content.bgColorCode!,
             color: content.textColorCode!,
             paddingTop: 20,
             wordBreak: 'keep-all',
           }}>
        <FroalaEditorView model={content.contents} />
      </div>
    </div>
  );
};

export default NotificationDetailPage;
