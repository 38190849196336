import NotificationTitle from "../../components/Notification/NotificationTitle";
import { useState} from "react";
import {nToast} from "../../plugins/reactToast";
import {Action, Bridge} from "../../plugins/bridge";
import useNotification from "../../hooks/useNotification";
import {BluePopUp} from "../../components/PopUp";
import {useUserDispatch, useUserState} from "../../context/UserContext";
import {getUserInfo, putUserAgreement} from "../../apis/user";
import {AgreementType} from "../../types/types";

const NotificationSettingPage = () => {
    const {
        form,
        onChangePushOn,
    } = useNotification();
    const [marketingAgreePopup, setMarketingAgreePopup] = useState(false);
    const {userInfo} = useUserState();
    const dispatch = useUserDispatch();

    const onChangePush = () => {
        if (userInfo?.agreements.includes('MARKETING')) {
            onChangePushOn();
        } else {
            setMarketingAgreePopup(true);
        }
    }

    const agreementSetup = () => {
        const agreement: AgreementType[] = userInfo?.agreements as AgreementType[] || [];
        putUserAgreement([...agreement, AgreementType.MARKETING])
            .then(() => {
                nToast('[칼하트윕] 마케팅 정보 수신 동의 처리');
                getUserInfo().then(r => {
                    dispatch({type: 'LOGIN', payload: r.data.payload});
                });
            });
    }

    return (
        <div>
            <BluePopUp
                title="마케팅 정보 수신 동의"
                description={`PUSH 알림 수신을 위해 \n우선 마케팅 정보 수신 동의가 필요합니다.`}
                buttonText="동의"
                subButtonText={'거부'}
                subButtonOnClick={() => nToast('[칼하트윕] 마케팅 정보 수신 거부 처리')}
                open={marketingAgreePopup}
                onClose={() => setMarketingAgreePopup(false)}
                onClick={agreementSetup}
            />

            <NotificationTitle title={'알림센터 설정'}/>
            <SettingCategory title={'PUSH 설정'} description={'칼하트윕의 PUSH 알림 수신 여부를 설정합니다.'}/>
            <SettingItem title={'[PUSH 알림]'} description={'칼하트윕의 PUSH 알림 수신 여부를 설정합니다.'}
                         checked={form.watch('pushOn')}
                         onChange={onChangePush}
            />
            <div className={'py-4 text-gray-500 text-12'}>
                ※ 칼하트윕의 중요 안내 사항 및 주문/배송에 대한 정보는 위 수신여부와 관계없이 발송됩니다.
            </div>

            <div className={'py-4 text-gray-500 text-12'}>
                PUSH를 설정하였으나 알림이 오지 않을 경우 휴대폰의 설정을 확인바랍니다. <br/>
                <button className={'underline text-black font-medium'}
                   onClick={() => Bridge.postMessage(Action.openSettings)}>
                    핸드폰 설정 바로가기 &gt;
                </button>
            </div>
        </div>
    )
}


type SettingCategoryProps = {
    title: string;
    description: string;
}

const SettingCategory = (props: SettingCategoryProps) => {
    return (
        <div className={'py-4'}>
            <div className={'font-bold text-14'}>{props.title}</div>
            <div className={'text-12'}>{props.description}</div>
        </div>
    )
}

type SettingItemProps = {
    title: string;
    description: string;
    checked: boolean;
    onChange: () => void;
}

const SettingItem = (props: SettingItemProps) => {
    return (
        <div className={'flex justify-between border-b p-4 -mx-4'}>
            <div>
                <div className={'font-bold text-12'}>{props.title}</div>
                <div className={'text-gray-500 text-12'}>{props.description}</div>
            </div>
            <Switcher10 checked={props.checked} onChange={props.onChange} />
        </div>
    )
}

type SwitcherProps = {
    checked: boolean;
    onChange: () => void;
}

const Switcher10 = (props: SwitcherProps) => {
  return (
    <>
      <label className='autoSaverSwitch relative inline-flex cursor-pointer select-none items-center'>
        <input
          type='checkbox'
          name='autoSaver'
          className='sr-only'
          checked={props.checked}
          onChange={props.onChange}
        />
        <span
          className={`slider flex h-[30px] w-[50px] items-center rounded-full p-1 duration-200 ${
            props.checked ? 'bg-black' : 'bg-[#CCCCCE]'
          }`}
        >
          <span
            className={`h-6 w-6 rounded-full bg-white duration-200 ${
              props.checked ? 'translate-x-[18px]' : ''
            }`}
          ></span>
        </span>
      </label>
    </>
  )
}

export default NotificationSettingPage;
