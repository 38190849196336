export enum BRAZE_EVENT {
    SIGN_UP = 'CH_sign_up_completed',
    SIGN_IN = 'CH_sign_in_completed',
    SIGN_OUT = 'CH_sign_out_completed',
    MAIN_HOME_PAGE_VIEWED = 'CH_main_home_page_viewed',
    MY_PAGE_VIEWED = 'CH_my_page_viewed',
    PRODUCT_MENU_CLICKED = 'CH_product_menu_clicked',
    CONTENT_MENU_CLICKED = 'CH_content_menu_clicked',
    CONTENT_VIEWED = 'CH_content_viewed',
    CONTENT_ITEM_CLICKED = 'CH_content_item_clicked',
    ITEM_TOTAL_PAGE_VIEWED = 'CH_item_total_page_viewed',
    ITEM_DETAIL_PAGE_VIEWED = 'CH_item_detail_page_viewed',
    ITEM_WISHLIST_ADDED = 'CH_item_wishlist_added',
    ADDED_TO_CART = 'CH_item_added_to_cart',
    DELETED_FROM_CART = 'CH_item_deleted_from_cart',
    DELETED_FROM_WISHLIST = 'CH_item_deleted_from_wishlist',
    CART_PAGE_VIEWED = 'CH_cart_page_viewed',
    PURCHASE_BUTTON_CLICKED = 'CH_purchase_button_clicked',
    ORDER_INITIALIZED = 'CH_order_initialized',
    ORDER_TRIED = 'CH_order_tried',
    ORDER_CONFIRMED = 'CH_order_confirm_completed',
    ORDER_CANCELLED = 'CH_order_canceled',
    RETURN_APPLYED = 'CH_return_apply_completed',
    EXCHANGE_APPLYED = 'CH_exchange_apply_completed',
    PURCHASE = 'Purchase',
    KEEP_SHOPPING_BUTTON_CLICKED = 'CH_keep_shopping_button_clicked'
}

export const brazeGenderCode = (gender: string) => {
    switch (gender) {
        case 'MALE':
            return 'm'
        case 'FEMALE':
            return 'f'
        default:
            return 'o'
    }
}