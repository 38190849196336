import {useHistory} from "react-router-dom";
import 'swiper/swiper.min.css';
import 'swiper/components/pagination/pagination.min.css';
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/effect-fade/effect-fade.min.css';
import SwiperCore, { Pagination, Navigation, Autoplay, EffectFade } from 'swiper/core';
import { Bridge } from '../plugins/bridge';
import {useUserState} from "../context/UserContext";
import {FC} from "react";

SwiperCore.use([Pagination, Navigation, Autoplay, EffectFade]);

interface HeaderBannerProps {
    css:string;
}

export const HeaderBanner: FC<HeaderBannerProps> = ({
                                                        css,
  }) => {
  const { push } = useHistory()
  const {isLoggedIn} = useUserState();
  return (
      <>
        <style>
          {`
              .swiper-container {
              direction: vertical !important;
          }
              `}
        </style>
          {!isLoggedIn && (
              <div
                  className={`flex absolute w-full top-0 tracking-wide overflow-hidden bg-gray-100 tx_3e52bb md:h-7 h-7 text-12 text-white z-50 font-semibold items-center justify-center cursor-pointer xl:-mb-0 -mb-0.5 ${css}`}
                  onClick={() => {
                      const journal_id = 330;
                      const journal_name = ('WELCOME COUPON');
                      const content = {
                          content_tab: "WELCOME COUPON",
                          content_id: String(journal_id),
                          content_name: journal_name
                      }
                      Bridge.sendAmplitude('main_content_clicked', content);
                      push(`/journal/${journal_id}`);
                  }}
              >
                  칼하트 윕 신규가입 &nbsp;<span className={`font-bold`}>앱&오프라인 5% 할인</span>&nbsp; 쿠폰&nbsp;<span
                  className={`mb-0.5`}>&gt;</span>
              </div>
          )}
      </>
  );
};
